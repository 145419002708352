import React, {Component} from "react";
import SEO from "../components/Seo/Seo";
import gsap from "gsap";
import "./styles/projects.scss";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {css} from "@emotion/core";

// function getRandom(min, max) {
//   return Math.random() * (max - min) + min;
// }
class Projects extends Component {
  // constructor(props) {
  //   super(props);
  // }
  componentDidMount() {
    // function spinCogs(paths) {
    //   var tl = new gsap.timeline()

    // }

    this.cogTl = new gsap.timeline();
    this.openingTl = new gsap.timeline()
      // .fromTo(
      //   ".page-title,.page-info",
      //   { autoAlpha: 0, y: 50 },
      //   { autoAlpha: 1, duration: 1, y: 0 }
      // )
      .fromTo(
        ".projects li",
        {autoAlpha: 0, y: 20},
        {autoAlpha: 1, y: 0, stagger: 0.1, duration: 1}
      )
      .fromTo(".skill", {autoAlpha: 0}, {autoAlpha: 1, stagger: 0.05}, "")
      // .to("span > svg", {
      //   startAt: {
      //     scaleX: 0,
      //     scaleY: 0,
      //     autoAlpha: 0
      //   },
      //   autoAlpha: 1,
      //   scaleX: 1,
      //   scaleY: 1,
      //   duration: 0.4
      //   // stagger: 0.5
      // })
      .to("span > svg", {
        rotate: 360,
        duration: 10,
        ease: "slow(0.7, .7,false)",
        repeat: -1,
        yoyo: false,
        transformOrigin: "center center",
        stagger: 0.01,
      });
  }
  render() {
    const projects = [
      {
        title: "Vodafone Group",
        date: "2021",
        skills: [
          "GatsbyJS",
          "ReactJs",
          "Component Library",
          "Server Side Rendering",
          "GraphQL",
          "Contentful CMS",
          "Multilanguage",
        ],
        info:
          "I am currently contracting for Vodafone Group on a GatsbyJS build integrating with Contentful CMS in a project that spans multiple countries. I can't say much more than that.",
      },
      {
        title: "Smacks Agency - Ventur",
        date: "2020",
        skills: [
          "VueJS",
          "NuxtJS",
          "Prismic CMS",
          "Styled Components",
          "Server Side Rendering",
          "Sass",
          "Netlify Hosting",
          "CSS Grid",
        ],
        info:
          "Built a bespoke portfolio site for a high end travel agency using Prismic CMS and NuxtJS to create a beautiful set of components and pages that could be rearranged to the needs of the client. Apart from the cool SSR tech working behind the scenes, the site incorporated modern design techniques that allowed me to harness the full power of CSS Grid to create a complex, responsive masterpiece.",
      },
      {
        title: "Vodafone UK",
        date: "2020",
        skills: [
          "Laravel",
          "Oracle CMS",
          "Javascript",
          "HTML",
          "Sass",
          "Remote",
        ],
        info:
          "I have been working as a contractor for Vodafone UK since March 2020. Apparently, I was the first person in the company to be onborded during the first lockdown completely remotely. I work on maintaining a few legacy codebases and have overseen and implemented a huge overhaul of the laravel codebase. This contract has also given me some experience with the Java Oracle CMS framework. I also gained experience collaborating with multiple teams in multiple countries on large, sprawling codebases and projects.",
      },
      {
        title: "malcolmfernandes.com",
        date: "2020",
        skills: [
          "GatsbyJS",
          "ReactJS",
          "Styled Components",
          "GSAP Animations",
          "Server Side Rendering",
          "Netlify Hosting",
          "Sass",
          "Modular CSS",
          "GraphQL",
        ],
        info:
          "Yeah, I'm talking about the site you are looking at right now. Made with love, late nights and GatsbyJS. I built this site to learn more about the Gatsby workflow and SSR. GSAP is handling the animations.",
      },
      {
        title: "Butterfly Cannon",
        date: "2019",
        skills: [
          "Wagtail",
          "Django",
          "Python",
          "Javascript",
          "HTML",
          "Sass",
          "Divio Cloud",
        ],
        info:
          "This was a bespoke portfolio build for the design agency, Butterfly Cannon. I worked with their in-house designers to craft a site that shows off their unique flare for design. I built the site with Wagtail and deployed it with Divio Cloud.",
      },
      {
        title: "MUMU Agency",
        date: "2015 - 2019",
        skills: ["Lead Frontend developer", "Project scoping", "Training"],
        info:
          "Having started with Mumu as a Junior Developer, I soon became the agency’s Lead Frontend Developer. This involved planning, implementing and maintaining the front end code for all the agency’s projects in addition to managing and training our new recruits. As well as some enterprise-level in-house applications (Eventcube, JourneyPal, MyEthos), I also worked on smaller bespoke CMS builds using Django (usually with Wagtail), Wordpress, Shopify and single-page applications.\n\n The following are various projects that I worked on during my time at Mumu:",
      },
      {
        title: "Eventcube",
        date: "(Mumu)",
        skills: ["VueJS", "Sass", "Laravel", "Tiwg", "Javascript", "JQuery"],
        info:
          "Eventcube is a white label ticketing platform and was the largest in-house project I worked on at Mumu. My role was to manage and contribute to the frontend code inside this large Laravel SaaS application. I helped build new features with VueJS and created bespoke themes that integrated with Eventcube's clients' website as well as building several microsites and holding pages. I was involved in refactoring large amounts of legacy code. I also built 2 versions of the informational site, eventcube.io, using Wagtail for SEO optimisation and dynamic content.",
      },
      {
        title: "Journey Pal",
        date: "(Mumu)",
        skills: [
          "Accessibility",
          "JQuery",
          "Javascript",
          "HTML",
          "Sass",
          "Django",
        ],
        info:
          "Journey PAL is a social network for disabled train users across the country to discover and share station accessibility information. This is to enable better planning for a long journey. I build all the frontend code for this project.",
      },
      {
        title: "Discover England Fund",
        date: "(Mumu)",
        skills: ["ReactJS", "Javascript", "Sass", "Single Page Application"],
        info:
          "Built Showcase site using ReactJS with an interactive SVG map that showed where and how the fund was being spent in the UK.",
      },
      {
        title: "My Ethos",
        date: "(Mumu)",
        skills: [
          "ReactJS",
          "VueJS",
          "Javascript",
          "Pug",
          "Material UI",
          "Element UI",
          "Modular CSS",
        ],
        info:
          "Worked on the styling for both admin and main application of site. Worked on the functionality of some of the React and Vue components.",
      },

      {
        title: "The Woodspeen",
        date: "(Mumu)",
        // date: "2018-2019",
        skills: ["Wagtail", "Django", "Python", "Javascript", "HTML", "Sass"],
        info:
          "Built a bespoke Wagtail CMS build which handled uploading recipes, dynamic menu creation and integration with booking system and Eventcube's ticketing system for cookery classes.",
      },
      {
        title: "Canova Hall - Martello Hall - Seratta Hall - Cativo Bar",
        date: "(Mumu)",
        skills: [
          "Wagtail Multi-site",
          "Django",
          "Python",
          "Javascript",
          "HTML",
          "Sass",
        ],
        info:
          "Designed and build a Multi-site Wagtail project that allowed a restaurant group to use the same page structure and CMS for each of their sites, whilst having unique themes, branding and bespoke pages.",
      },
      {
        title: "Sancho Panza",
        date: "(Mumu)",
        skills: [
          "Wagtail",
          "Django",
          "Python",
          "Javascript",
          "Scrollmagic",
          "GSAP",
          "HTML",
          "Sass",
        ],
        info:
          "Made and maintained a Wagtail build for the events company Sancho Panza, who used the site to promote their yearly music festival and showcase their 30+ year history in the world of dance music.",
      },
      {
        title: "IHS Towers",
        date: "(Mumu)",
        // date: "2018 - 2020",
        skills: ["Wagtail", "Django", "Python", "Javascript", "HTML", "Sass"],
        info:
          "Built custom pages and features for pre-existing Wagtail build. Updating content that required changes to both the CMS and the codebase.",
      },
      {
        title: "Yazoo",
        date: "(Mumu)",
        // date: "2018 - 2019",
        skills: ["ReactJS", "Wordpress", "Headless CMS", "Sass"],
        info:
          "Took over the site maintenance on a project built by another agency. I updated pages with new product's assets and copy, implementing loading animations. Also built single page promotional sites for advertising campaigns.",
      },
      {
        title: "Sacla",
        date: "(Mumu)",
        skills: ["Wordpress", "HTML", "CSS", "JQuery", "Javascript"],
        info:
          "Maintained UK Wordpress website, updating copy and updating styling and layout.",
      },
      {
        title: "Optiwell",
        date: "(Mumu)",
        skills: ["Wordpress", "HTML", "CSS", "JQuery", "Javascript"],
        info:
          "I performed site maintenance on their main Wordpress site and built micro-sites and pages for marketing campaigns.",
      },
      {
        title: "Farr Festival",
        date: "(Mumu)",
        skills: ["Wagtail", "Django", "Python", "Javascript", "HTML", "Sass"],
        info:
          "Constructed sites for Farr Festival 3 years in a row using Wagtail as bespoke CMS, implemented custom publishing features and an interactive festvial programme combining artists, stages and media rich content.",
      },
      {
        title: "The Ransom Note",
        date: "(Mumu)",
        skills: [
          "Javascript",
          "Sass",
          "JQuery",
          "HTML",
          "HTML Email",
          "Django",
        ],
        info:
          "Built the frontend for v2 of The Ransom Note's online music magazine. Also oversaw the Shopify build for the Ransom Note Records' online store.",
      },
      {
        title: "Red and Yellow Care",
        date: "(Mumu)",
        skills: [
          "Wagtail",
          "Django",
          "Python",
          "Javascript",
          "HTML",
          "Sass",
          "A/B Testing",
        ],
        info:
          "Created a Wagtial Multi-site build to have individual Doctor sites all managed from one CMS. Build also allowed multiple versions of a site for A/B Testing.",
      },
    ];
    return (
      <div className="container">
        <div className="center-content">
          <SEO
            title="Projects"
            description="A brief overview of the projects I have worked on over the years."
          />
          <h1 className="page-title">Projects</h1>
          <p className="page-info">
            I have worked on a broad range of projects as a developer, taking on
            a few different roles and utilising different skills to build
            solutions and tackle problems.
            <br />
            <br />
            Here is a run down of some of the projects I have worked on...
          </p>
          <ul className="projects">
            {projects &&
              projects.map((project, i) => {
                return (
                  <li key={i}>
                    <h2 className="title">{project.title}</h2>

                    {project.date && <h5>{project.date}</h5>}
                    <div className="sub">
                      {project.skills &&
                        project.skills.map(skill => (
                          <h6 className="skill" key={skill}>
                            <span>
                              <FontAwesomeIcon
                                icon="cog"
                                size="sm"
                                // spin={true}
                                color="#ff7601"
                              />
                            </span>
                            {skill}
                          </h6>
                        ))}
                    </div>
                    <p
                      css={css`
                        white-space: pre-wrap;
                      `}
                    >
                      {project.info}
                    </p>
                    {i < projects.length - 1 && <hr />}
                  </li>
                );
              })}
          </ul>
          <p
            css={css`
              margin-bottom: 20px;
            `}
          >
            Would you like to discuss working together?
          </p>
          <AniLink fade duration={1} to="/contact/">
            <button className="btn">
              <FontAwesomeIcon icon={["fas", "envelope"]} size="sm" />
              Get in touch{" "}
            </button>
          </AniLink>
        </div>
      </div>
    );
  }
  // </Layout>
}

export default Projects;
